import React from 'react'
import { FaFolderOpen, FaFile, FaWindowClose, FaSearch } from 'react-icons/fa'
//import { FcCancel } from 'react-icons/fc' 
import { TiCancel, TiDocument } from 'react-icons/ti'

export default function BeeIcon(props) {
    switch(props.icon) {
        case 'new'    : return <FaFile />
        case 'close'  : return <FaWindowClose />
        case 'create' : return <TiDocument />
        case 'open'   : return <FaFolderOpen />
        case 'search' : return <FaSearch />
        case 'cancel' : return <TiCancel />
        
        default       : return <></>
    }
}