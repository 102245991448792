import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import HanddleActions from '../actions'
import { t } from '../services'

import Editor from "@monaco-editor/react";
import { Row, Col, Container } from 'reactstrap';

export default function DashboardPlay(props) {
    
    const [code, setCode]                   = useState('const msg = "hello world!"\n\nconsole.log(msg)')
    const [result, setResult]               = useState("")
    
    const [theme, setTheme]                 = useState("vs-dark")
    const [language, setLanguage]           = useState("javascript")
    const [isEditorReady, setIsEditorReady] = useState(false)

    const dispatch  = useDispatch();
    const editorRef = useRef()

    let action = {dispatch, dataValues: {}}
    let params = {}

    useEffect(()=> {

        if(props.params.tab !== "playcode") return

        editorMount()
        //HanddleActions(action, {})  

    }, [props.params.tab])

    useEffect(() => {
        const log = console.log
        const logMessages = []

        
        console.log = function(content) {
            try {
                logMessages.push.apply(logMessages, arguments)
                log.apply(console, arguments)
    
                content = typeof content === "object"
                            ? JSON.stringify(content) 
                            : content

                if(typeof content !== "function")
                    setResult(content)
                
            } catch (e) {
            }
        }

        try {
            eval(code)
        } catch (e) {
            setResult(e.toString())
        }
    }, [code])

    const handles = {
    }

    function editorMount() {
        setIsEditorReady(true)
        handleEditorDidMount(editorRef)
    }

    function handleEditorDidMount(editor) {
        editorRef.current = editor;

        setIsEditorReady(true)
    }
    
    function toggleTheme() {
        setTheme(theme === "light" ? "dark" : "light");
    }

    return (
    <Container fluid id="module_code" className="bg-primary mt-3">
        <Row className="btns_files">
            <Col sm="12">        
                <Row style={{height: "60vh"}}>
                    {
                    isEditorReady &&
                    <Editor 
                        theme={theme}
                        language={language}
                        loading={"Loading..."}
                        editorDidMount={handleEditorDidMount}
                        //editorWillMount={editorWillMount}
                        value={code}
                        onChange={(value, e) => setCode(value)}
                    />
                    }
                </Row>
                <Row style={{height: "calc(30vh - 54px)"}}>
                    <textarea id="console" className={"w-100"} value={result} readOnly>
                    </textarea>
                </Row>
            </Col>
        </Row>
    </Container>
    )
}