import React, { useState, useRef } from "react";
import { CardImg, CardBody, Card, CardSubtitle, CardTitle, CardText, Jumbotron, Row, Col, Button, Nav, NavItem, NavLink, Container, ListGroup, ListGroupItem } from 'reactstrap';
import { FaFolder } from 'react-icons/fa'
import { FiHash } from 'react-icons/fi'

export default function DashboardEditor() {
    const [Projects, setProjects] = useState({});

    return (
    <Container className="bg-primary mt-4 p-3" fluid>
        <Row>
            <Col sm="2">
                <div>
                    <ListGroup>
                        <ListGroupItem active tag="a" href="#" action>Channels</ListGroupItem>
                        <ListGroupItem tag="a" href="#" action><FiHash /> Main</ListGroupItem>
                        <ListGroupItem tag="a" href="#" action><FiHash /> Work</ListGroupItem>
                        <ListGroupItem disabled tag="a" href="#" action></ListGroupItem>
                    </ListGroup>
                    <p />
                    <ListGroup>
                        <ListGroupItem active tag="button" action></ListGroupItem>
                        <ListGroupItem disabled tag="button" action></ListGroupItem>
                    </ListGroup>
                    </div>
            </Col>
            <Col sm="8">
                    <Jumbotron>
                        <h6>Fulano <small>01/01/2020 14:40</small></h6>
                        <p>Mensagem</p>
                    </Jumbotron>

                    <Jumbotron>
                    <h6>Fulano <small>01/01/2020 14:40</small></h6>
                    </Jumbotron>

                    <Jumbotron>
                    <h6>Fulano <small>01/01/2020 14:40</small></h6>
                    </Jumbotron>
                <Row>
                    <Col sm="4">
                    </Col>
                </Row>

            </Col>
            <Col sm="2">
                <div>
                    <ListGroup>
                        <ListGroupItem active tag="a" href="#" action>Online (2)</ListGroupItem>
                        <ListGroupItem tag="a" href="#" action><FiHash /> naldomalk</ListGroupItem>
                        <ListGroupItem tag="a" href="#" action><FiHash /> fulano</ListGroupItem>
                        <ListGroupItem disabled tag="a" href="#" action></ListGroupItem>
                    </ListGroup>
                    <p />
                    <ListGroup>
                        <ListGroupItem active tag="button" action>Offline (5)</ListGroupItem>
                        <ListGroupItem tag="button" action><FaFolder /> Ciclano</ListGroupItem>
                        <ListGroupItem tag="button" action><FaFolder /> Beltrano</ListGroupItem>
                        <ListGroupItem tag="button" action><FaFolder /> Jose</ListGroupItem>
                        <ListGroupItem tag="button" action><FaFolder /> Maria</ListGroupItem>
                        <ListGroupItem tag="button" action><FaFolder /> João</ListGroupItem>
                        <ListGroupItem disabled tag="button" action></ListGroupItem>
                    </ListGroup>
                    </div>
            </Col>
        </Row>
    </Container>
    )
}