export const modules = {
  dash: {},

  projects : {
    parent: null,
    title: 'Project | Projects',
    icon: '',
    view: { form: 'formProjects', model: 'projects', dataTitle: 'Project', dataSubtitle: 'Description', dataDescription: 'Description', layout: '<div>teste</div>' },
    
    actions: { 
      connectLocalhost : { TODO: 'TODO...' },

      versions:   { type: 'SHOW_FORM', form: 'formProjectsBranches', data: {}, mode: 'search' },
      new:        { type: 'SHOW_FORM', data: {}, preAction: 'close', mode: 'new' },
      saveNew:    { route: '/api/projects', method: 'POST', type: 'SHOW_FORM', form: 'formProjects', mode: 'search' },
      search:     { route: '/api/projects', method: 'GET', type: 'SHOW_FORM', form: 'formProjects', mode: 'search' },
      saveEdit:   { route: '/api/projects/:IDProject', method: 'PUT', type: 'SHOW_FORM', form: 'formProjects', mode: 'search' },
      edit:       { route: '/api/projects/:IDProject', method: 'GET', type: 'SHOW_FORM', mode: 'edit' },
      open:       { route: '/api/projects/:IDProject', method: 'GET', type: 'OPEN_DATA' },
      saveDelete: { route: '/api/projects/:IDProject', method: 'DELETE', type: 'OPEN_DATA' },
      delete:     { type: 'SHOW_MODAL_CONFIRM', modal: 'modalConfirmPassword' }, //TODO
      close:      { type: 'CLOSE_DATA' },

      thread : { type: 'GET_DATA', method: 'GET', route: '/api/feed/productions', receiver: 'getFeed', finally: (bee)=> setTimeout(()=> bee.dispatch(bee.module.actions.init), 5000) }
    }
    
  },

  projects_branches : {
    parent: null,
    title: 'Branch | Branches',
    icon: '',
    anchor: 'side',
    view: { form: 'formProjectsBranches', model: 'projects_branches', dataTitle: `Project_branch`, dataSubtitle: '', dataDescription: '', layout: null },
    actions: { 
      new:         { type: 'SHOW_FORM', preAction: 'close', mode: 'new' },
      saveNew:     { route: '/api/projects/branches', method: 'POST', type: 'SHOW_FORM',  mode: 'search' },
      search:      { route: '/api/projects/branches', method: 'GET', type: 'SHOW_FORM', mode: 'search' },
      edit:        { route: '/api/projects/branches/:IDProject_branch', method: 'GET', type: 'SHOW_FORM' },
      open:        { route: '/api/projects/branches/:IDProject_branch', method: 'GET', type: 'OPEN_DATA'  },
      saveDelete:  { route: '/api/projects/branches/:IDProject_branch', method: 'GET', type: 'OPEN_DATA'  },
      delete:      { type: 'SHOW_MODAL', modal: 'modalConfirmPassword', action: 'saveDelete' }, //TODO
      close:       { type: 'CLOSE_DATA' }
    }
    
  },

  projects_modules : {
    parent: 'projects_branches',
    title: 'Module | Modules',
    icon: '',
    init: 'search',
    view: { form: 'formProjectsModules', model: 'projects_modules', dataTitle: 'Project_module', dataSubtitle: '', dataDescription: '', layout: null },
    actions: { 
      new:        { type: 'SHOW_FORM', preAction: 'close', mode: 'new' },
      saveNew:    { route: '/api/project/:IDProject', method: 'POST', type: 'SHOW_FORM',  mode: 'search' },
      search:     { route: '/api/projects/:IDProject?relZZ=versions.modules', method: 'GET', type: 'SHOW_FORM', mode: 'search' },
      update:     { route: '/api/project/:IDProject', method: 'PUT', type: 'SHOW_FORM',  mode: 'search' },
      edit:       { route: '/api/project/:IDProject', method: 'GET', type: 'SHOW_FORM' },
      open:       { route: '/api/project/:IDProject', method: 'GET', type: 'OPEN_DATA'  },
      saveDelete: { route: '/api/project/:IDProject', method: 'DELETE', type: 'OPEN_DATA' }, 
      delete:     { type: 'SHOW_MODAL', modal: 'modalConfirmPassword' }, 
      close:      { type: 'CLOSE_DATA' }
    }
    
  },

  projects_actions : {
    parent: 'projects_branches',    
    title: 'Action | Actions',
    icon: '',
    view: { form: 'formProjectsActions', model: 'projects_actions', dataTitle: 'Action', dataSubtitle: 'Description', dataDescription: 'Description', layout: null },
    actions: { 
      new: { type: 'SHOW_FORM', preAction: 'close', mode: 'new' },
      search: { route: '/api/projects/actions', method: 'GET', type: 'SHOW_FORM', mode: 'search' },
      create: { route: '/api/project/actions', method: 'POST', type: 'SHOW_FORM',  mode: 'search' },
      update: { route: '/api/project/actions', method: 'PUT', type: 'SHOW_FORM',  mode: 'search' },
      edit: { route: '/api/project/actions/:IDAction', method: 'GET', type: 'SHOW_FORM' },
      open: { route: '/api/project/:IDProject', method: 'GET', type: 'OPEN_DATA'  },
      delete: { type: 'SHOW_MODAL_CONFIRM', modal: 'modalConfirmPassword' }, //TODO
      close: { type: 'CLOSE_DATA' }
    }
    
  },

  projects_forms : {
    parent: 'projects_branches',    
    title: 'Form | Forms',
    icon: '',
    view: { form: 'formProjectsForms', model: 'projects_forms', dataTitle: 'formTitle', dataSubtitle: '', dataDescription: '', layout: null },
    actions: { 
      new: { type: 'SHOW_FORM', preAction: 'close' },
      search: { route: '/api/projects/forms', method: 'GET', type: 'SHOW_FORM', mode: 'search' },
      create: { route: '/api/project/forms', method: 'POST', type: 'SHOW_FORM',  mode: 'search' },
      update: { route: '/api/project/forms', method: 'PUT', type: 'SHOW_FORM',  mode: 'search' },
      edit: { route: '/api/project/forms/:IDForm', method: 'GET', type: 'SHOW_FORM' },
      open: { route: '/api/project/:IDForm', method: 'GET', type: 'OPEN_DATA'  },
      delete: { type: 'SHOW_MODAL_CONFIRM', modal: 'modalConfirmPassword' }, //TODO
      close: { type: 'CLOSE_DATA' }
    }
    
  },

  projects_keys : {
    parent: 'projects',    
    title: 'Key | Keys',
    icon: '',
    view: { form: 'formProjectsKeys', model: 'projects_keys', dataTitle: 'Key_type', dataSubtitle: 'key', dataDescription: 'Description', layout: null },
    actions: { 
      new: { type: 'SHOW_FORM', preAction: 'close' },
      search: { route: '/api/projects/actions', method: 'GET', type: 'SHOW_FORM', mode: 'search' },
      create: { route: '/api/project/actions', method: 'POST', type: 'SHOW_FORM',  mode: 'search' },
      update: { route: '/api/project/actions', method: 'PUT', type: 'SHOW_FORM',  mode: 'search' },
      edit: { route: '/api/project/actions/:IDAction', method: 'GET', type: 'SHOW_FORM' },
      open: { route: '/api/project/:IDProject', method: 'GET', type: 'OPEN_DATA'  },
      delete: { type: 'SHOW_MODAL_CONFIRM', modal: 'modalConfirmPassword' }, //TODO
      close: { type: 'CLOSE_DATA' }
    }
    
  },

  words : {
    parent: null,
    title: 'Word | Words',
    icon: '',
    actions: {
      new: { type: 'SHOW_FORM', form: 'formWords' },
      search: { route: '/api/system/langs/words', method: 'GET', type: 'SHOW_FORM', form: 'formWords', mode: 'search' },
      create: { route: '/api/system/langs/words', method: 'POST', type: 'SHOW_FORM', form: 'formWords' },
      update: { route: '/api/system/langs/words', method: 'PUT', type: 'SHOW_FORM', form: 'formWords' },
      open: { route: '/api/system/langs/words/:IDLang_words', method: 'GET', type: 'OPEN_DATA'},
      edit: { route: '/api/system/langs/words/:IDLang_words', method: 'GET', type: 'SHOW_FORM', form: 'formWords'},
      delete: { type: 'SHOW_MODAL_CONFIRM', modal: 'modalConfirmPassword'},
      close: { type: 'CLOSE_DATA' },
    },
    view: { model: 'langs_words', dataTitle: 'Lang_words', dataSubtitle: '', dataDescription: '', layout: null }
  },

  words_translations : {
    parent: 'words',
    title: 'Translation | Translations',
    icon: '',
    actions: {
      new: { type: 'SHOW_FORM', form: 'formWordsTranslations' },
      search: { route: '/api/system/langs/words/translations', method: 'GET', type: 'SHOW_FORM', form: 'formWords', mode: 'search', debug: true },
      create: { route: '/api/system/langs/words/translations', method: 'POST', type: 'SHOW_FORM', form: 'formWordsTranslations' },
      update: { route: '/api/system/langs/words/translations', method: 'PUT', type: 'SHOW_FORM', form: 'formWordsTranslations' },
      open: { route: '/api/system/langs/words/translations/:IDWords_translation', method: 'GET', type: 'OPEN_DATA'},
      edit: { route: '/api/system/langs/words/translations/:IDWords_translation', method: 'GET', type: 'SHOW_FORM', form: 'formWordsTranslations'},
      delete: { route: '/api/system/langs/words/translations/:IDWords_translation', method: 'GET', type: 'SHOW_MODAL_CONFIRM', form: 'modalConfirmDelete'},
      close: { type: 'CLOSE_DATA' },
    },
    view: { model: 'langs_words_translations', dataTitle: 'Lang_words_translated', dataSubtitle: '', dataDescription: '', layout: null }
  }

}