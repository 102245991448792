export const forms = {
    login : {
      title: 'Make_your_login',
      schema: {
        Email: {  type: "email", class: "required", placeholder: 'Your_email', focus: true },
        Password: { type: "password", class: "required", placeholder: 'Your_password' }
      },
      buttons: {
        Login: { route: '/api/user/login', method: 'POST', type: 'HIDDEN_FORM' },
        Cancel: { type: 'HIDDEN_FORM' },
        Recover_password: { type: 'SHOW_FORM', form: 'passwordRecover' }
      },
      global: true,
    },
  
    logout : {
      title: 'Are_you_sure_?',
      schema: {
      },
      buttons: {
        Yes: { route: '/api/user/logout', method: 'DELETE', type: 'HIDDEN_FORM' },
        No: { type: 'HIDDEN_FORM' },
      },
      global: true
    },
  
    register: {
      title: 'Create_a_new_account',
      schema: {
        Name: {type: "text", class: "required", placeholder: 'Your_fullname', focus: true },
        Email: {type: "email", class: "required", placeholder: 'Your_email' },
        Password: {type: "password", class: "required", placeholder: 'Your_password' },
        RePassword: {type: "password", class:"required", label: 'Confirm_password', placeholder: 'Confirm_your_password', validation: 'RePassword.value==Password.value' }
      },
      buttons: {
        Create: { route: '/api/api/users/create', method: 'post', type: 'SHOW_FORM', form: 'project', label: 'Create_account'},
        Cancel: { type: 'HIDDEN_FORM' },
      },
      global: true,
    },
  
    passwordChange: {
      title: 'Change your password',
      schema: {
        Password: {type: "password", class: "required", placeholder: 'Your_current_password' },
        NewPassword: {type: "password", class: "required", label: 'New_password', placeholder: 'Your_new_new_password' },
        RePassword: {type: "password", class:"required", label: 'Confirm_password', placeholder: 'Confirm_new_your_password', validation: 'RePassword.value==NewPassword.value' }
      },
  
      buttons: {
        Create: { route: '/api/users/password', method: 'put', type: 'SHOW_FORM', form: 'project'},
        Cancel: { type: 'HIDDEN_FORM' },
      },
  
      global: true,
    },

    passwordRecover: {
      title: "Password_recover",
      schema: {
        Email: {type: "email", class: "required", placeholder: 'Enter_your_email' },
      },
      buttons: {
        Recover: { route: '/api/users/password/recover', method: 'post', type: 'SHOW_FORM', form: 'passwordRecoverConfirm'},
        Cancel: { type: 'HIDDEN_FORM' },
      },
      global: true,
    },

    passwordRecoverConfirm : {
      title: "Confirm_code",
      schema: {
        Code: {type: "text", class: "required", placeholder: 'Enter_the_code_sent_to_your_email' },
      },
  
      buttons: {
        Recover: { route: '/api/users/password/recover', method: 'post', type: 'SHOW_FORM', form: 'passwordRecover'},
        Cancel: { type: 'HIDDEN_FORM' },
      },

      global: true,
    },
  
    formProjects : {
      schema: {
        Project: {type: "text", class: "required", placeholder: "Name_of_project" },
        Description: {type: "textarea", class: "", placeholder: "Resume_of_project" },
        IDProject_category: {label: 'Project Category', type: "select", route: '/api/system/form/options/IDProject_category', options: [{val: '', opt: ''}] },
        IDProject_relation: {label: 'Project Relation', type: "select", route: '/api/system/form/options/IDProject_relation', options: [{val: '', opt: ''},{val: 0, opt: 'This is the main project'}] }
      },
  
      buttons: {
        New: { if: "show.if.mode.search", action: 'new' },
        Create: { if: "show.if.mode.new", action: 'saveNew' },
        Update: { if: "show.if.mode.edit", action: 'saveEdit' },
        Change: { if: "show.if.mode.view", onClick:"bee.editing = true" },
        Close: { if: true, type: 'HIDDEN_FORM' }
      },

      search: {
        table: ['Project', 'Version', 'Description'],
        buttons: ['new', 'close']
      }

    },

    formProjectsBranches : {
      schema: {
        IDProject: {label: 'Project', type: "select", route: '/api/system/form/options/IDProject', options: [{val: '', opt: ''}] },
        Project_branch: {label: 'Branch', type: "text", class: "required", placeholder: "Branch of project" }
      },
  
      buttons: {
        New: { if: "show.if.mode.search", action: 'new' },
        Create: { if: "show.if.mode.new", action: 'saveNew' },
        Update: { if: "show.if.mode.edit", action: 'update' },
        Close: { if: true, type: 'HIDDEN_FORM' }
      },

      search: {
        table: ['IDProject','Project_branch'],
        buttons: ['new', 'close']
      }

    },

    formProjectsModules : { 
      schema: {
        Project_module: {label: 'Module Name', type: "text", placeholder: "" },
        IDProject_module_parent: {label: 'Module parent', type: "select", route: '/api/projects/branches/:IDProject_branch/modules', options: [{val: '', opt: ''}, {val: 0, opt: 'this is a main module'}] },
        Module_title: {label: "Title", type: "text", placeholder: "" },
        View_model: {label: 'Data Model', type: 'text', divider: 'Data View'},
        View_title: {label: "Field for Title", type: "text", placeholder: "" },
        View_subtitle: {label: "Field for Subtitle", type: "text", placeholder: "", null: true },
        View_description: {label: "Field for Description", type: "text", placeholder: "", null: true },
        Layout: {type: "code", class: "", placeholder: "", null: true, divider: 'Custumization' },
      },

      buttons: {
        New: { if: "show.if.mode.search", action: 'new' },
        Create: { if: "show.if.mode.new", action: 'saveNew' },
        Update: { if: "show.if.mode.edit", action: 'update' },
        Change: { if: "show.if.mode.view", onClick:"bee.editing = true" },
        //cancel: { if: true, type: 'HIDDEN_FORM' },
        Close: { if: true, type: 'HIDDEN_FORM' }
      },

      search: {
        table: ['IDParent', 'Module_title', 'View_model', 'View_data_title'],
        buttons: ['New', 'Close']
      }

    },

    formProjectsActions : {
      schema: {
        IDProject_module: {label: 'Module', type: "select", route: '/api/projects/modules/:IDProject', options: [{val: '', opt: ''}, {val: '0', opt: 'main'}] },
        Action: {label: 'Action Name', type: "text", class: "required", placeholder: "Name of action", help:"new, update, close" },
        Type: {label: 'Action Type', type: "select", options: [{val: '', opt: ''},{val: 'SHOW_FORM', opt: 'Show a Form'}, {val: 'HIDDEN_FORM', opt: 'Hidden a From'},{val: 'OPEN_DATA', opt: 'Open data from API'}, {val: 'SEND_DATA', opt: 'Send data to API'},] },
        
        Form: { if: "show.if.values['Type'] == 'SHOW_FORM'", type: "text", placeholder: "Route of API" }, 
        
        Method: { if: "['OPEN_DATA','SEND_DATA'].includes(show.if.values['Type'])", label: 'Method', type: "select", options: [{val: '', opt: ''},{val: 'GET', opt: 'GET (request)'},{val: 'POST', opt: 'POST (create)'}, {val: 'POST', opt: 'PUT (update)'}, {val: 'DELETE', opt: 'DELETE (remove)'}] },
        Route: { if: "show.if.values['Method']", type: "text", placeholder: "API url" },
        
        Description: {type: "textarea", class: "", placeholder: "Resume of action" }
      },
  
      buttons: {
        New: { if: "show.if.mode.search", action: 'new' },
        Create: { if: "show.if.mode.new", action: 'saveNew' },
        Update: { if: "show.if.mode.edit", action: 'update' },
        Change: { if: "show.if.mode.view", onClick:"bee.editing = true" },
        //Cancel: { if: true, type: 'HIDDEN_FORM' },
        Close: { if: true, type: 'HIDDEN_FORM' }
      },

      search: {
        table: ['Project', 'Version', 'Description'],
        buttons: ['new', 'close'],
        //type: 'fields'
      }

    },


    formProjectsForms : {
      schema: {
        IDProject_module: {label: 'Module', type: "select", action: 'main.actions.IDProject_module' },
        Form: {label: 'Form Name', type: "text", placeholder: "", help:"" },

        Schema: { type: "layout" }, 
        Buttons: { type: "layout" }, 
        Search: { type: "layout" },
      },
  
      buttons: {
        New: { if: "show.if.mode.search", action: 'new' },
        Create: { if: "show.if.mode.new", action: 'saveNew' },
        Update: { if: "show.if.mode.edit", action: 'update' },
        Change: { if: "show.if.mode.view", onClick:"bee.editing = true" },
        Close: { if: true, type: 'HIDDEN_FORM' }
      },

      search: {
        table: ['Form'],
        buttons: ['new', 'close'],
      }

    },
  

    cloudDB : {
      init: { route: '/api/cloud', method: 'get', type: 'SHOW_FORM_DATA', form: 'projectList' },
  
      buttons: {
        New: { type: 'SHOW_FORM', form: 'formProject' },
        Cancel: { type: 'HIDDEN_FORM' },
      },

      title: 'Cloud',
      table: ['Database', 'Type', 'Label', 'Address'],
      search: true
    },

    formWords : {
      model: 'langs_words',

      schema: {
        Lang_words: {type: "text", class: "", placeholder: "Word or phrase", label: "Word string" }
      },
  
      buttons: {
        New: { action: 'new' },
        Create: { if: "!bee.data", action: 'saveNew' },
        Update: { if: "bee.editing", action: 'update' },
        Change: { if: "bee.data", onClick:"bee.editing = true" },
        Cancel: { if: true, type: 'HIDDEN_FORM' },
        Close: { if: true, type: 'HIDDEN_FORM' }
      },

      search: {
        table: ['Lang_words'],
        buttons: ['new', 'close']
      }
    },

    formWordsTranslations : {
      schema: {
        IDLang_words: { rels: "langs_words.IDLang_words CASCATE" },
        IDLang: {label: 'Language', type: "select", route: '/api/system/form/options/IDLang', options: [{val: '', opt: ''}] },
        Translation: {type: "text", class: "required", placeholder: "Word translated" }
      },
  
      buttons: {
        Create: { if: "!bee.data", route: '/api/translation', method: 'POST', type: 'SHOW_FORM', form: 'formTranslations' },
        Update: { if: "bee.editing", route: '/api/translation', method: 'PUT', type: 'SHOW_FORM', form: 'formTranslations' },
        Change: { if: "bee.data", onClick:"bee.editing = true" },
        Cancel: { if: true, type: 'HIDDEN_FORM' },
        Close: { if: true, type: 'HIDDEN_FORM' }
      },

      model: 'langs_words'
    }
  }