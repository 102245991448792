import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HanddleActions from '../actions'
import BeePagination from './BeePagination'
import { Container, Form, Row, Col, Input,  Table, Spinner } from 'reactstrap'
import { TextField, Select, MenuItem, InputLabel, FormControl, FormGroup, Button } from '@material-ui/core';
import { fade, ThemeProvider, withStyles, makeStyles, createMuiTheme, } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { Fade } from 'react-reveal'
import { BsSearch, BsPencilSquare, BsTrashFill } from 'react-icons/bs'
import BeeIcon from "../beeFront/BeeIcon";
import { t } from "../services/langs"

export default function BeeForm(props) {

  const [filter, setFilter] = useState('')
  const [values, setValues] = useState({})

  const options   = useSelector(state => state.formReducer.options)
  const form      = useSelector(state => state.formReducer.form)
  const data      = useSelector(state => state.formReducer.data)
  const isLoading = useSelector(state => state.formReducer.isLoading)

  const dispatch  = useDispatch()
  const module    = props.module || {}
  const mode      = props.mode
  const schema    = form.schema
  const buttons   = form.buttons
  const bee       = props.bee
  const action    = bee || {dispatch}

  module.actions = module.actions || {}

  useEffect(()=> {
    if(!isLoading && mode && form.init && !data)
      HanddleActions(action, {...form.init, dataValues: values})

    if(mode!=='search')
      for(let field in schema)
        if(schema[field].type === 'select' && !options[field])
          HanddleActions(action, {...schema[field], type: 'SHOW_FORM_OPTIONS', dataBind: module.dataBind})

    setValues(!data ? {} : data[form.model] || data[module.view.model] || {})
  }, [form, data])


  const theme = createMuiTheme({
    palette: {
      primary: orange,
    }
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiFormGroup-root': {
        margin: theme.spacing(1)
      },
      '& .MuiTextField-root': {
        //margin: theme.spacing(1)
      },
      '& .MuiFilledInput-root': {
        backgroundColor: 'white'
      },
      '& .MuiInputLabel-root': {
      }
    },
    button: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  let show = {}
  show = {...show, if: { values: values, mode: {[mode]:true} } }

  return <Form className={`beeform ${form.name} mode-${mode || 'view'} ` + classes.root } autoComplete="off">
            <ThemeProvider theme={theme}>
              { 
                mode!='search' && 
                <Fade up><h6>{t((mode=='new' ? 'New ' : '')) + t(form.title || module.title)}</h6><br /></Fade> 
              }
              {
                mode!='search' && schema && !isLoading &&
                Object.keys(schema)
                  .map((field, i) => {

                    let showIf = schema[field].if
                                  ? eval(schema[field].if)
                                  : true

                      return !showIf 
                                ? null
                                : <Fade key={`beeform-group-${i+1}`} down>
                                    <FormGroup>
                                    {  
                                      schema[field].divider && 
                                      <h6>{t(schema[field].divider)}</h6> 
                                    }
                                    {
                                      schema[field].type != 'select'
                                      ? <TextField 
                                          id="filled-basic" 
                                          variant="filled"
                                          className={classes.input}
                                          name={field} 
                                          type={schema[field].type || 'text'}
                                          fullWidth
                                          //variant="filled"
                                          label={t(schema[field].label ?? field)}
                                          defaultValue={values[field]}
                                          value={values[field]}
                                          onChange={(e)=> setValues({...values, [field]:e.target.value})}
                                          //placeholder={schema[field].placeholder} 
                                          disabled={isLoading} 
                                          //helperText="" 
                                        />
                                      : schema[field].route && !options[schema[field].route.split('/').slice(-1)[0]]
                                        ? <Spinner type="grow" color="secondary" /> 
                                        : <TextField
                                            select 
                                            id="filled-basic" 
                                            variant="filled"
                                            className={classes.input}
                                            name={field} 
                                            type={schema[field].type || 'select'}
                                            fullWidth
                                            //variant="filled"
                                            label={t(schema[field].label ?? field)}
                                            defaultValue={values[field]}
                                            value={values[field]}
                                            onChange={(e)=> setValues({...values, [field]:e.target.value})}
                                            //placeholder={} 
                                            disabled={isLoading} 
                                            //helperText="" 
                                            //SelectProps={{native: true }}
                                          >
                                          {
                                            schema[field].options
                                              && schema[field].options
                                                  .map((opt) => <MenuItem  
                                                                  key={'_opt' + field + opt.val} 
                                                                  value={opt.val}>
                                                                    {opt.opt}
                                                                </MenuItem>)
                                          }
                                          {
                                            schema[field].options
                                              && schema[field].route
                                              && (options[schema[field].route.split('/').slice(-1)[0]] || []) 
                                                    .map((opt, i) => <MenuItem  
                                                                        key={field + '_' + i + '_' + opt.val}
                                                                        value={opt.val}>
                                                                          {opt.opt}
                                                                      </MenuItem>)
                                          }
                                        </TextField>
                                    }
                                  </FormGroup>
                              </Fade> 
                  }   
                )
              }
              { 
                mode=='search' && 
                <section>
                  {
                    !module.data
                      ? <Spinner type="grow" color="secondary" /> 
                      : <Container fluid="true">
                          <Fade down>
                            <Row className="pb-3">
                              <Col>{module.titleS || ''}</Col>
                                <Col>
                                  <Input placeholder="Filter" onChange={(e)=>setFilter(e.target.value)} autoFocus/>
                                </Col>
                                <Col className="text-right">
                                  { 
                                    buttons && form.search && form.search.buttons &&
                                    form
                                    .search
                                    .buttons
                                    .map((button, i) => <Button 
                                                            key={`btn_${i}`} 
                                                            className="ripple" 
                                                            onClick={() => buttons[button].onClick
                                                                            ? buttons[button].onClick
                                                                            : HanddleActions(action, {...module.actions[buttons[button].action] || {}, ...buttons[button], dataValues: values})
                                                                    }>
                                                              <BeeIcon icon={button.toLowerCase()} /> 
                                                              {t(buttons[button].action ?? button)}
                                                          </Button>) 
                                  }
                                </Col>
                            </Row> 
                          </Fade> 
                            { 
                            form.search && module.data &&                
                            <BeeTable
                              bee={bee}
                              module={module} 
                              form={form} 
                              filter={filter}
                              action={action}
                            />
                            }
                      </Container>
                  }
                    </section> 
              }
              { 
                mode != 'search' && !isLoading && 
                <Fade up>
                      <Row className="buttons-down" fluid="true">
                      {
                        isLoading
                          ? <Spinner type="grow" color="secondary" />
                          : buttons && 
                            Object
                            .keys(buttons)
                            .map((button, i)=> {

                              //console.log(buttons[button].if)
                              
                              let showIf = buttons[button].if
                                            ? eval(buttons[button].if)
                                            : true

                              let fn =  function() { // TODO refazer
                                          alert()
                                          eval(buttons[button].onClick);
                                        }

                              return !showIf
                                      ? null
                                      : <Button 
                                          key={`btn__${i}`} 
                                          //className={'ripper bee-button-'+button} 
                                          variant="contained"
                                          color="default"
                                          className={classes.button +' beebtn-' + button}
                                          startIcon={<BeeIcon icon={button.toLowerCase()} />}
                                          onClick={() => buttons[button].onClick
                                                          ? fn() // TODO refazer
                                                          : HanddleActions( 
                                                                            action,
                                                                            {...module.actions[buttons[button].action] || {}, ...buttons[button], dataValues: values, dataBind: values}
                                                                          )
                                        }>
                                          {t(buttons[button].title ?? button)}
                                        </Button>
                            })
                      } 
                      </Row>
                    </Fade>
              }
            </ThemeProvider>
        </Form>
}

const BeeTable = function(props) {

  const bee   = props.bee

  let columns = props.form.search.table
  let module  = props.module
  let filter  = props.filter
  let table   = []

  let max   = 25
  let total = module.data.length
  let page  = 1

  let start = (page * max) - max 

  module.data.map((d, i) => {
    if(i < start || table.length >= max) return

    let obj      = {beeIndex: i}
    let toFilter = ' '
    
    columns.map((f) => { 
      obj[f]    = d[f]
      toFilter += (d[f] || '') + ' '
    })
    
    if(!filter || toFilter.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
      let i = 0;

      if(filter.length>=3) {
        if(toFilter.toLocaleUpperCase().indexOf(' '+filter.toLocaleUpperCase()) > -1) i++
        if(toFilter.toLocaleUpperCase().indexOf(' '+filter.toLocaleUpperCase()+' ') > -1) i++
        if(i && toFilter.indexOf(filter) > -1) i++
        if(i && toFilter.indexOf(' '+filter) > -1) i++
        if(i && toFilter.indexOf(' '+filter+' ') > -1) i++
      }
      
      obj._sortPriority = i
       
      table.push(obj)
    }
  })

  table.sort((a, b) => b._sortPriority - a._sortPriority)
  
  const handleOpen = function(row) {
    HanddleActions(props.bee, {type: 'HIDDEN_FORM'})
    HanddleActions(props.bee, {...props.module.actions.open, dataBind: module.data[row.beeIndex]})
    
    props.action.history.push(`/${bee.routeParams.lang}/dash/${props.module.parent}/-/${props.module.name}`)
  }

  const handleEdit = function(row) {

    HanddleActions(props.bee, {...props.module.actions.edit, dataBind: module.data[row.beeIndex]})
  }

  const handleDelete = function(row) {

    //TODO
    //HanddleActions(props.action, {...props.module.actions.delete, dataBind: module.data[row.beeIndex]})
  }

  return !props.module.data
          ? <Spinner type="grow" color="secondary" />
          : <div className="w-100">
              <Table className="radius" dark fluid="true">
                <thead>
                  <tr>
                    <th className="text-left">#</th>
                    { columns.map((field, i) => <th key={'th_'+i}>{field.trim()}</th> ) }
                    <th className="text-right">{'Actions'}</th>
                  </tr>
                </thead>
                <tbody>
                    { 
                      table
                        .map((row, i) => <tr key={'row_'+i} 
                                              className="ripple"
                                              onDoubleClick={() => handleOpen(row) }>
                                            {
                                              Object.keys(row).map((field, ii) => 
                                                <td key={'field_'+i+'-'+ii}>{row[field]}</td>)
                                            }
                                            <td className="text-right">
                                              <a onClick={() => handleOpen(row) }><BsSearch /></a>
                                              &nbsp; &nbsp; 
                                              <a onClick={() => handleEdit(row) }><BsPencilSquare /></a>
                                              &nbsp; &nbsp; 
                                              <a onClick={() => handleDelete(row) }><BsTrashFill /></a>
                                            </td>
                                          </tr>)
                    }
                </tbody>
              </Table>
              <BeePagination />
          </div>
}