import { MODEL_CREATE_DB, MODEL_CREATE_TABLES, MODEL_STATEMENT, MODEL_STATEMENT_EXECUTE } from '../actions/actionsTypes';

const initialState = {
  dbs: {},
  files: {},
  statement: ''
};

export const modelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_CREATE_DB:
    return {
        ...state,
        isLoading: false,
        statement: action.data
    };
    case MODEL_CREATE_TABLES:
      return {
          ...state,
          isLoading: false,
          statement: action.data
      };
    case MODEL_STATEMENT:
        return {
            ...state,
            statement: action.data || initialState.statement
        };
    case MODEL_STATEMENT_EXECUTE:
        return {
            ...state,
            statement_execute: action.data
        };    
    default:
      return state;
  }
};