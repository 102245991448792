import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default function BeePagination(props) {
  return (
    <Pagination aria-label="navigation">
    <PaginationItem>
        <PaginationLink className="bg-beehive-dark" first href="#" />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" previous href="#" />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" href="#">
          1
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" href="#">
          2
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" href="#">
          3
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" href="#">
          4
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" href="#">
          5
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" next href="#" />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink className="bg-beehive-dark" last href="#" />
      </PaginationItem>
    </Pagination>
  );
}