import { Alert } from 'reactstrap';
import { OPEN_MODULE, OPEN_DATA, SEND_DATA, CLOSE_DATA, OPEN_PROJECT, REMOTE_CONNECT, REMOTE_CONNECT_SUCCESS } from '../actions/actionsTypes';
import { modules } from '../beeModules/modules';

const getModule = function(moduleName, getChilds = true) {
  let module = modules[moduleName] || modules['projects']

  if(!module) return false

  module.childs = []

  if(getChilds)
    for(let key in modules)
      if(modules[key].parent == moduleName)
        module.childs.push(getModule(key))

  //if(module.parent)
    //module.parent = getModule(module.parent, false)

  if(modules[module.parent]) {
    module.parentModule = modules[module.parent]
    module.parentTitle  = modules[module.parent].title
    module.parentTitleS = modules[module.parent].titleS
  }
    

  return module
}

const setDataView = function(module, data) {
  module.dataView = {}

  if(!data || !module.view) 
      return module
 
  module.dataView.title       = (data[module.view.model] || {})[module.view.dataTitle] || ''
  module.dataView.subtitle    = (data[module.view.model] || {})[module.view.dataSubtitle] || ''
  module.dataView.description = (data[module.view.model] || {})[module.view.dataDescription] || ''
  module.data = data

  return module
}

for(let m in modules) {
  let module = modules[m]
  let titles = modules[m].title 

  titles = titles  
            ? titles.split('|')
            : ['####','####']

  module.data     = null 
  module.dataView = {}
  module.name     = m
  module.title    = titles[0]
  module.titleS   = titles[1]

  modules[m] = module
}

for(let m in modules)
  modules[m] = getModule(m)

const initialState = {
  modules: modules,
  module: getModule('projects'),
  data: {},
  error: null,
  connected: false,
  connectPort: 1987,
  connectUrl: 'http://localhost',
  connectData: {}
};

export const modulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODULE:
      return {
          ...state,
          modules: modules,
          module: getModule(action.module) || state.module,
          data: (modules[action.module] || {}).data || {},
        };
    case OPEN_DATA:
      return {
        ...state,
        data: action.data || state.data,
        module: setDataView(state.module, action.data),
        error: action.error || state.error
      };
    case SEND_DATA:
        return {
          ...state,
          error: action.error || state.error,
        };
    case CLOSE_DATA:
      return {
          ...state,
          data: {},
          error: null
        };
    case OPEN_PROJECT:
    return {
        ...state,
        project: action.data || state.project,
      };
    case REMOTE_CONNECT:
    return {
        ...state,
        isLoading: true,
        connected: false,
        project: {},
      };
    case REMOTE_CONNECT_SUCCESS:
    return {
        ...state,
        isLoading: false,
        connected: true,
        connectData: action.data,
    };
    default:
      return state;
  }
};