export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const OPEN_MODULE = 'OPEN_MODULE';
export const OPEN_DATA = 'OPEN_DATA';
export const SEND_DATA = 'SEND_DATA';
export const CLOSE_DATA = 'CLOSE_DATA';

export const OPEN_PROJECT = 'OPEN_PROJECT';
export const REMOTE_CONNECT = 'REMOTE_CONNECT';
export const REMOTE_CONNECT_SUCCESS = 'REMOTE_CONNECT_SUCCESS';

export const SHOW_FORM = 'SHOW_FORM';
export const SHOW_FORM_DATA = 'SHOW_FORM_DATA';
export const SHOW_FORM_OPTIONS = 'SHOW_FORM_OPTIONS';
export const HIDDEN_FORM = 'HIDDEN_FORM';
export const IS_LOADING = 'IS_LOADING';
export const SHOW_ERROR = 'SHOW_ERROR';

export const MODEL_CREATE_DB = 'MODEL_CREATE_DB'
export const MODEL_CREATE_TABLES = 'MODEL_CREATE_TABLES'
export const MODEL_STATEMENT = 'MODEL_STATEMENT'
export const MODEL_STATEMENT_EXECUTE = 'MODEL_STATEMENT_EXECUTE'

export const PATHS     = 'PATHS'
export const PATH_DATA = 'PATH_DATA'