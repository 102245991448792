import axios from 'axios'
import { configAxios } from '../configs'
import { REMOTE_CONNECT } from './actionsTypes';

export default async function HanddleActions(action, params) {
  console.log(action)
  console.log(params)

  let result = {}

  if(!action)
    return alert("error...")

  const dispatch   = action.dispatch 
  const dataValues = params.dataValues || {}

  if(params.debug || 1==2)
    console.log('debug from HandleActions:') || console.log(params)

  if(params.preAction && action.module)
      dispatch(action.module.actions[params.preAction])

  if(action.module)
    params = {...params, currentModule: action.module, currentForm: (action.module.view||{}).form }

  !params.route
    ? dispatch(params)
    : dispatch({...params, isLoading: params.isLoading ?? true })
      && await axios(
          configAxios( params.url??'1', dataBind(params.route, params.dataBind), params.method||'GET', dataValues )
        )
        .then(res => {
            result = {...params,
                callback:  true,
                type:      res.data.action || params.type || 'OPEN_DATA',
                data:      res.data.data,
                error:     res.data.error,
                counters:  res.data.counters,
                origin:    'axiosCallback'
            }
            dispatch(result)
        })
        .catch((error) => {
          (error.response && (error.response.data || {}).type)
            ? dispatch(Object.assign({}, params, {...error.response.data.action, error: error.response.error}))
            : dispatch({type: 'SHOW_FORM', isLoading: false, error: error})
      }); 

  return result 
}

export const clickRemoteConnect = value => ({
  type: REMOTE_CONNECT,
  newValue: value
});

const dataBind = function(route, dataBind) {
  if(dataBind)
    for(let field in dataBind)
      route = route.replace(':'+field, dataBind[field])

  return route
}