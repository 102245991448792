import React, { useState, useRef } from "react";
import { Card, CardTitle, CardText, Jumbotron, Row, Col, Button, Container, ListGroup, ListGroupItem } from 'reactstrap';
import { FaFolder, FaDatabase } from 'react-icons/fa';
import { GrMysql } from 'react-icons/gr';
import { AiOutlineUserAdd } from 'react-icons/ai';

export default function ModuleCloud() {
    const [Clouds, setCloud] = useState({});

    return (
    <Container className="bg-primary p-2" fluid>
        <Row>
            <Col sm="3">
                <ListGroup className="shadow">
                    <ListGroupItem active tag="a" href="#" action>Databases</ListGroupItem>
                    <ListGroupItem tag="a" href="#" action><GrMysql /> &nbsp; &nbsp; Add MySQL</ListGroupItem>
                    <ListGroupItem tag="a" href="#" action><AiOutlineUserAdd /> &nbsp; &nbsp; Add User</ListGroupItem>
                </ListGroup>
                <p />
                <ListGroup className="shadow">
                    <ListGroupItem active tag="button" action>My Databases</ListGroupItem>
                    <ListGroupItem tag="button" action><FaDatabase /> MySQL Free - db1</ListGroupItem>
                    <ListGroupItem tag="button" action><FaDatabase /> MySQL Free - db2</ListGroupItem>
                    <ListGroupItem tag="button" action><FaDatabase /> MySQL Free - db3</ListGroupItem>
                    <ListGroupItem tag="button" action><FaDatabase /> MySQL Free - db4</ListGroupItem>
                    <ListGroupItem tag="button" action><FaDatabase /> MySQL Free - db5</ListGroupItem>
                </ListGroup>
            </Col>
            <Col sm="9">
                <Row>
                    <Col sm="12">
                        <Jumbotron className="shadow"></Jumbotron>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
    )
}