import React from "react";
import { useParams, useHistory } from "react-router-dom";

import BeeRender from "../beeFront/BeeFront";

import { Container, Nav, NavItem, NavLink, TabContent, TabPane, Row } from 'reactstrap';
import { FaProjectDiagram, FaTasks, FaDatabase, FaPlay } from 'react-icons/fa';
import { IoMdCloud  } from 'react-icons/io';
import { AiOutlineTeam } from 'react-icons/ai';
import { GoCode  } from 'react-icons/go';

import Module from "./Module";
import DashboardTeam from "./DashboardTeam";
import ModuleCloud from "./ModuleCloud";
import ModuleModels from "./ModuleModels";
import ModuleREST from "./ModuleREST";
import DashboardEditor from "./DashboardEditor";
import DashboardPlay from "./DashboardPlay";

export default function Dashboard() {
  const routeParams = useParams()
  const history     = useHistory()
  
  const tab  = routeParams.tab
  const tabs = [
    {title: 'Project Manager', name: 'dash', icon: FaProjectDiagram },
    //{title: 'Teams', name: 'team', icon: AiOutlineTeam },
    {title: 'Cloud', name: 'cloud', icon: IoMdCloud },
    {title: 'Model Tools', name: 'models', icon: FaDatabase },
    {title: 'REST Tools', name: 'rest', icon: FaTasks },
    {title: 'Editor', name: 'editor', icon: GoCode },
    {title: 'JS Play Code', name: 'playcode', icon: FaPlay }
  ]

  let tabShow = tabs
                .find((tb) => tb.name == tab)
                  ? tab
                  : 'dash' 
  
  return <Container fluid>
            <BeeRender />
            <Row className="tabs bg-primary shadow">
                <Nav tabs>
                  {tabs.map((tab)=>
                  <NavItem 
                    key={tab.name}>
                        <NavLink 
                          className="ripple"
                          active={tab.name === tab}
                          onClick={()=> history.push('/' + routeParams.lang + '/' + tab.name)}
                        >
                          <tab.icon /> 
                          {tab.title}
                        </NavLink>                  
                  </NavItem>
                  )}
              </Nav>           
            </Row>

            <TabContent id="modules" className="mt-2" activeTab={tabShow}>
              <TabPane tabId="dash">
                <Module 
                  routeParams={routeParams} 
                />
              </TabPane>

              <TabPane tabId="team">
                <DashboardTeam />
              </TabPane>

              <TabPane tabId="cloud">
                  <ModuleCloud />
              </TabPane>

              <TabPane tabId="models">
                  <ModuleModels params={routeParams} />
              </TabPane>

              <TabPane tabId="rest">
                  <ModuleREST params={routeParams} />
              </TabPane>
                
              <TabPane tabId="editor">
                  <DashboardEditor params={routeParams} />
              </TabPane>

              <TabPane tabId="playcode">
                  <DashboardPlay params={routeParams} />
              </TabPane>

            </TabContent>
          </Container>   
  }

