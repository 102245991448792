import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import HanddleActions from '../actions'
import ControlledEditor  from "@monaco-editor/react";
import { Row, Col, Container, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { AiFillDatabase, AiOutlineConsoleSql, AiFillPlaySquare } from 'react-icons/ai'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { t } from '../services/'

import * as SRD from "storm-react-diagrams"
require("storm-react-diagrams/dist/style.min.css")

export default function ModuleModels(props) {

    const module    = useSelector(state => state.modulesReducer.module)  
    const connected = useSelector(state => state.modulesReducer.connected)  
    const data      = useSelector(state => state.modulesReducer.connectData)    
    const statement = useSelector(state => state.modelsReducer.statement)

    const [project, setProject]       = useState({})
    const [filter, setFilter]         = useState('')
    const [checkedAll, setCheckedAll] = useState(false)
    const [showEditor, setShowEditor] = useState(false)

    let action     = {dispatch: useDispatch(), dataValues: {}}
    let params     = {
            create_db : {type: 'MODEL_CREATE_DB', url: 0, route: '/beedev/MODEL_CREATE_DB', method: 'POST', dataValues: { models: project.models } },
            create_table : {type: 'MODEL_CREATE_TABLES', url: 0, route: '/beedev/MODEL_CREATE_DB', method: 'POST', dataValues: { models: project.models } },
            statement_execute : {type: 'MODEL_STATEMENT_EXECUTE', url: 0, route: '/beedev/MODEL_STATEMENT_EXECUTE', method: 'POST' },
            statement: {type: 'MODEL_STATEMENT' },
    }

    const valueGetter = useRef()

    useEffect(()=> {

        setProject({...module, models: data.models || {}})

    }, [module, data])

    useEffect(()=> {
    }, [statement])

    function handleEditorDidMount(_valueGetter) {

        valueGetter.current = _valueGetter
    }

    function handleModelChecked(key) {
        let _project = {...project}

        _project.models[key]._checked = !_project.models[key]._checked

        setShowEditor(false)
        setProject(_project)
    }

    function handleModelcheckedAll(param) {
        let _project = {...project}

        HanddleActions(action, params.statement)
        setCheckedAll(param)

        Object
        .keys(_project.models)
        .map(key => _project.models[key]._checked = param)

        setShowEditor(false)
        setProject(_project)
    }

    // ------------------------------------- diagram --------------------------------- //

    // 1) setup the diagram engine
    var engine = new SRD.DiagramEngine();
    engine.installDefaultFactories();

    // 2) setup the diagram model
    var diagram    = new SRD.DiagramModel();
    var models     = []
    var lastHeight = 0 
    
    //var project    = module
    //project.models = data.models

    if(project.models)
        for(let model of Object.keys(project.models)){
            let node   = new SRD.DefaultNodeModel(model, "rgb(0,192,255)")
            let i      = models.length + 1
        
            for(let column in project.models[model].schema){
                node.addInPort(column)
                node.addOutPort(column)
            }
                
            node.setPosition(100, (100 * i) + 20);

            lastHeight = Object.keys(project.models[model].schema).length + 1 * 20

            //let link1 = port.link(port2);

            models.push(node)
        }

    for(let node of models)
        diagram.addNode(node)

    // 3) create a default node
    var node1 = new SRD.DefaultNodeModel("Node 1", "rgb(0,192,255)");
    let port1 = node1.addOutPort("Out");
    node1.setPosition(100, 100);

    // 4) create another default node
    var node2 = new SRD.DefaultNodeModel("Node 2", "rgb(192,255,0)");
    let port2 = node2.addInPort("In");
    node2.setPosition(400, 100);

    // 5) link the ports
    let link1 = port1.link(port2);

    // 6) add the models to the root graph
    //diagram.addAll(node1, node2, link1)

    // 7) load model into engine
    engine.setDiagramModel(diagram);

    return (
    <Container className="bg-primary mt-3 p-3" fluid>
        <Row>
            <Col sm="3">
                <ListGroup className="shadow">
                    <ListGroupItem active action>SQL Statements</ListGroupItem>
                    <ListGroupItem tag="button" href="#" onClick={()=> setShowEditor(true)} action className={!connected ? 'disabled' : ''}><AiOutlineConsoleSql /> &nbsp; &nbsp; {t("New_Statement")} </ListGroupItem>
                    <ListGroupItem tag="button" href="#" onClick={()=> setShowEditor(true) || HanddleActions(action, {...params.create_db})} action className={!connected ? 'disabled' : ''}><AiOutlineConsoleSql /> &nbsp; &nbsp; {t("Create_Tables")} </ListGroupItem>
                    <ListGroupItem tag="button" href="#" onClick={()=> setShowEditor(true) || HanddleActions(action, {...params.statement_execute, dataValues: { statement_execute: valueGetter.current() } })} action className={!showEditor ? 'disabled' : ''}><AiFillPlaySquare /> &nbsp; &nbsp; {t("Execute_Statement")} </ListGroupItem>
                </ListGroup>
                <p></p>
                <ListGroup className="shadow">
                    <ListGroupItem active action>
                        {connected ? t('Remote_Models') : t('Project_Models')}
                        <Input 
                            placeholder={t("Filter_Models")} 
                            onKeyUp={(e)=>setFilter(e.target.value)} 
                            disabled={!connected ? true : false}
                        />
                    </ListGroupItem>
                    <ListGroupItem 
                        onClick={()=>handleModelcheckedAll(!checkedAll)}
                        tag="button"
                        className={!connected ? 'disabled' : ''}
                        action>
                            {t("All")}
                            {
                                checkedAll
                                ? <MdCheckBox className="float-right" />
                                : <MdCheckBoxOutlineBlank className="float-right" />
                            }
                    </ListGroupItem>
                    {
                    connected && 
                    Object
                        .keys(project.models||{})
                        .map((key)=> {
                            let model = project.models[key]

                            if(filter == '' || key.indexOf(filter) > -1)
                                return (
                                    <ListGroupItem 
                                        key={key} 
                                        onClick={()=>handleModelChecked(key)}
                                        tag="button"
                                        action>
                                            <AiFillDatabase /> {key}
                                            {
                                                model._checked
                                                ? <MdCheckBox className="float-right" />
                                                : <MdCheckBoxOutlineBlank className="float-right" />
                                            }
                                    </ListGroupItem>
                                )
                        })
                    }
                </ListGroup>
            </Col>
            <Col sm="9">
                <section className="shadow" style={{ height: "100%" }}>
                    {
                    connected && showEditor
                    ? <ControlledEditor 
                            height="90vh" // By default, it fully fits with its parent
                            theme={'vs-dark'}
                            language={'sql'}
                            loading={"Loading..."}
                            value={statement}
                            editorDidMount={handleEditorDidMount}
                        />
                    : <SRD.DiagramWidget
                        diagramEngine={engine} 
                     />
                    }
                </section>
            </Col>
        </Row>
    </Container>
    )
}