import { LOGIN, LOGOUT } from '../actions/actionsTypes';

const initialState = {
  user:   JSON.parse(localStorage.getItem('user') || '{}'),
  logged: !localStorage.getItem('user') ? false : true
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem('user', JSON.stringify(action.data))
    return {
        ...state,
        logged: true,
        user: action.data,
      };
    case LOGOUT:
      localStorage.removeItem('user')
      return {
          ...state,
          logged: false,
          user: {},
        };
    default:
      return state;
  }
};