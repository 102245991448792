import { useParams } from "react-router-dom";
import pt from './pt'

const translations = {
    en: {},
    pt,
    br: pt,
    es: {},
}

export const langs       = ['en','pt','br','es']
export const defaultLang = "en"

export const t = function(word = "") {
    const params = {} //useParams()

    let lang = params.lang || "en"

    return (translations[lang][word] || word).replace(/_/g," ")
}