import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import HanddleActions from '../actions'
import { t } from '../services'

import ControlledEditor  from "@monaco-editor/react";
import { Row, Col, Container, ListGroup, ListGroupItem, Input, Button } from 'reactstrap';
import { TreeFilesPaths } from '../components'
import { IconArrowFolderDown, IconArrowFolderRight, IconClose, IconJS, IconCSS } from '../components/icons'

export default function DashboardEditor(props) {
    
    const paths     = useSelector(state => state.editorReducer.paths)
    //const path_data = useSelector(state => state.editorReducer.path_data)    

    const [mapPaths, setMapPaths]           = useState([])
    const [filter, setFilter]               = useState('')
    const [code, setCode]                   = useState("'hello world!'") //TEMP
    const [path, setPath]                   = useState({})
    const [pathData, setPahData]            = useState({})
    const [pathsTabs, setPathsTabs]         = useState([])

    const [theme, setTheme]                 = useState("vs-dark")
    const [language, setLanguage]           = useState("javascript")
    const [isEditorReady, setIsEditorReady] = useState(false)

    const dispatch  = useDispatch();
    const editorRef = useRef()

    let action = {dispatch, dataValues: {}}
    let params = {
                    PATHS: {type: 'PATHS', url: 0, route: '/beedev/PATHS', method: 'POST', dataValues: { path: '' } },
                    PATH_DATA: {type: 'PATH_DATA', url: 0, route: '/beedev/PATH_DATA', method: 'POST', dataValues: { path: '...' } },
                 }

    useEffect(()=> {

        if(props.params.tab !== "editor") return

        editorMount()
        HanddleActions(action, {...params.PATHS})  

    }, [props.params.tab])

    
    useEffect(() => {
        async function loadPaths() {
            let _paths = await HanddleActions(action, {...params.PATHS})

            _paths = (_paths.data || [])
                    .map(path=> {
                        let array  = path.split("/")
                        let name   = array.pop()
                        let ext    = name.indexOf(".") > -1 ? name.split(".").pop() : ""
                        let parent = array.join("/")
                            
                        return {
                            path,
                            name,
                            ext,
                            parent,
                            open: false,
                            data: "",
                        }
                    });

            setMapPaths(_paths)
        };

        if(!mapPaths.length) loadPaths();
    }, [dispatch]);

    const handles = {
        openPath: async function(obj) {
            if(!obj)
                obj = pathsTabs.length >= 1
                        ? pathsTabs[pathsTabs.length-2]
                        : {path: "./", data: "", ext: "txt"}

            if(obj?.path.indexOf(".") > -1 && !pathData[obj.path]) {
                // file
                if(!obj.data) {
                    let api = await HanddleActions(action, {...params.PATH_DATA, dataValues: { path: obj.path }})
                    obj.data = api.data.path_data 
                }
                setCode(obj.data)
                setPath(obj)
                setLanguage(obj.ext === "js" ? "javascript" : "json")
        

                if(!pathsTabs.find(p => p.path == obj.path))
                    setPathsTabs(pathsTabs.concat([obj]))
            } else {
                let _mapPaths = Object.assign([], mapPaths)

                _mapPaths
                    .filter(p => p.path === obj.path)
                    .map(p => { 
                                p.open = !p.open 
                              }    
                    )

                setMapPaths(_mapPaths)
            }
        },

        closePath: function(obj) {
            setPathsTabs(Object.assign([],pathsTabs.filter(p => p.path !== obj.path)))
           
            if(obj.path == path.path)
                this.openPath()
        }
    }

    function editorMount() {
        setIsEditorReady(true)
        handleEditorDidMount(editorRef)
    }

    function handleEditorDidMount(editor) {
        editorRef.current = editor;

        setIsEditorReady(true)
    }
    
    function toggleTheme() {
        setTheme(theme === "light" ? "dark" : "light");
    }

    console.log("render...")
    console.log(mapPaths)

    return (
    <Container fluid id="module_code" className="bg-primary mt-3 p-3">
        <Row className="btns_files">
            <Col sm="3">
                <ListGroup className="shadow">
                    <ListGroupItem active tag="button" action>
                        {t('Files')}
                        <Input 
                            placeholder={t("Filter")} 
                            onKeyUp={(e)=>setFilter(e.target.value)}
                            disabled={!paths?.length ? "disabled" : ""}
                        />
                    </ListGroupItem>
                </ListGroup>

                <TreeFilesPaths {...{
                                    mapPaths, 
                                    filter, 
                                    handles, 
                                    style: {height: "calc(90vh - 110px)"} 
                                    } 
                                } />
            </Col>
            <Col sm="9">
                <Row>
                    {
                        pathsTabs
                        .map((_path, i) => { 
                            
                            return <Button  
                                        key={`btn_file_${i}`}
                                        active={_path.path == path?.path}
                                        style={{backgroundColor: "#202124"}}
                                        onClick={()=>handles.openPath(_path)}>
                                        <IconJS className="mr-2" />
                                        {`${_path.name}`} <IconClose onClick={(e)=>{e.stopPropagation();handles.closePath(_path)}} className="ml-4" />
                                    </Button>
                        })
                    }
                </Row>        
                <Row style={{height: "70vh"}}>
                    {
                    isEditorReady &&
                    <ControlledEditor 
                        //height="600px" // By default, it fully fits with its parent
                        theme={theme}
                        language={language}
                        loading={"Loading..."}
                        editorDidMount={handleEditorDidMount}
                        //editorWillMount={editorWillMount}
                        value={code}
                    />
                    }
                </Row>
                <Row style={{height: "calc(20vh - 54px)"}}>
                    <textarea id="console" className={"w-100"}>console</textarea>
                </Row>
            </Col>
        </Row>
    </Container>
    )
}