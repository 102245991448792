import { SHOW_FORM, SHOW_FORM_DATA, SHOW_FORM_OPTIONS, HIDDEN_FORM, IS_LOADING } from '../actions/actionsTypes'
import { forms } from '../beeModules/forms'

// TODO pegar online

const getForm = function(formName) {

  if(!formName)
      return null

  let form  = forms[formName] || {}
  form.name = formName

  return form
}

const initialState = {
  forms:     forms,
  form:      null,
  mode:      null,
  data:      null,
  error:     false,
  isLoading: false,
  options: {}
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FORM:
    return {
      ...state,
      form:         getForm(action.form || action.currentForm) || state.form,
      data:         action.data || null,
      mode:         action.mode || null,
      error:        action.error || false,
      isLoading:    action.isLoading || false,
    };

    case SHOW_FORM_OPTIONS:
      return {
            ...state,
            options: action.data 
                      ? Object.assign({}, state.options, action.data) 
                      : state.options
          };

    case SHOW_FORM_DATA:
      return {
        ...state,
        data:       action.data,
        mode:       action.mode,
        isLoading:  !action.callback,
      };

    case HIDDEN_FORM:
    return initialState;

    case IS_LOADING:
    return {
      ...state,
      isLoading:    true
    };

    default:
      return state;
  }
};