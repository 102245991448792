import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap'
import { t } from '../services/langs'

let menus = {
    login : {
        hidden: '(!$user)',
        childs: {
            sigIn : {
                action: {type: 'SHOW_FORM', form: 'formLogin' }
            },
            register: {
                action: {type: 'SHOW_FORM', form: 'formLogin' },
            },
        }
    },
    
    user : {
        title: `($user)`,
        hidden: '($user)',
        childs: {
            account: {
                action: {type: 'SHOW_FORM', form: 'formAccount' },
            },
            logout : {
                action: {type: 'SHOW_FORM', form: 'formLogout' }
            },
        }
    },

    modules : {
    },

    tools : {
    },

    help : {
        link: 'http://beejs.org',
        blank: true
    }

}

export default function Menu() {
    const [isOpen, setIsOpen] = useState(false)
 
    const toggle = ()=> setIsOpen(!isOpen)

    const user     = useSelector(state => state.usersReducer.user)  
    const logged   = useSelector(state => state.usersReducer.logged)  
    const form     = useSelector(state => state.formReducer.form)   
    const dispatch = useDispatch()

    return <Navbar className="bg-primary" dark expand="md">
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="mr-3">{user.Name || 'Account'}</DropdownToggle>
                    <DropdownMenu left="true">
                    { !logged && <DropdownItem onClick={()=> dispatch({type: 'SHOW_FORM', form: 'login'})}>{t("Login")}</DropdownItem> }
                    { !logged && <DropdownItem onClick={()=> dispatch({type: 'SHOW_FORM', form: 'register'})}>{t("Register")}</DropdownItem> }
                    { logged && <DropdownItem onClick={()=> dispatch({type: 'SHOW_FORM', form: 'passwordChange'})}>{t("Change_password")}</DropdownItem>} 
                        <DropdownItem divider />
                    { logged && <DropdownItem onClick={() => dispatch({type: 'SHOW_FORM', form: 'logout'})}>{t("Logout")}</DropdownItem>}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="mr-3">Modules</DropdownToggle>
                    <DropdownMenu left="true">
                    <DropdownItem><Link to="/en/dash/projects">Projects</Link></DropdownItem>
                    <DropdownItem><Link to="/en/dash/projects_branches">Branches</Link></DropdownItem>
                    <DropdownItem><Link to="/en/dash/words">Words</Link></DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem className="mr-3">
                    <NavLink href="#">Components</NavLink>
                </NavItem>
                <NavItem className="mr-3">
                    <NavLink href="#">Tools</NavLink>
                </NavItem>
                <NavItem className="mr-3">
                    <NavLink href="http://beejs.org" target="_BLANK">Help</NavLink>
                </NavItem>
                </Nav>
                <NavbarText><h5 className="color_logo">Beehive</h5></NavbarText>
                <NavbarBrand href="/" className="mr-5 color_logo" hidden>hive</NavbarBrand>
            </Collapse>
            </Navbar>
}