import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Container,  Row } from 'reactstrap'
import Menu from './pages/Menu'
import BeeForm from './beeFront/BeeForm'
import Dashboard from './pages/Dashboard.js'
import './assets/css/app.css';

function App() {
  const [configs, setConfigs] = useState({})
  const [isOpen, setIsOpen]   = useState(false)
  const form = useSelector(state => state.formReducer.form)

  useEffect(()=> {
    setConfigs({
      domain: window.location.toString().split("//")[1].split("/")[0].split(":")[0]
    })
  }, [])

  useEffect(()=> {
    if(configs.domain)
      console.log(configs.domain)
  }, [configs])

  return (
    <Router>
      <Switch>
      <Route exact path="/">
        <Redirect to="/en/dash" />
      </Route>
      <Route exact path="/:lang">
        <Redirect to="/en/dash" />
      </Route>
      <Route exact path={['/:lang/:tab', '/:lang/:tab/:module', '/:lang/:tab/:module/:id', '/:lang/:tab/:parent/:idParent/:module', '/:lang/:tab/:parent/:idParent/:module/:id', '/:lang/:tab/*/:parent/:idParent/:module']}>
        <div className="bg-over"></div>
        <Menu />
        <Container id="main" className="" fluid>
          <Row>
            {
              form && form.global
                ? <Container className="beeglobal beesection bg-primary text-light p-4 mt-4">
                    <BeeForm />
                  </Container>
                : <Dashboard /> 
            }
          </Row>
        </Container>
      </Route>
      </Switch>
    </Router>
  );
}

export default App;