import { PATHS, PATH_DATA } from '../actions/actionsTypes';

const initialState = {
  paths: [],
  path_data: "",
  path: ""
};

export const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATHS:
    return {
        ...state,
        paths: action.data || []
    };
    case PATH_DATA:
      return {
          ...state,
          path_data: action.data?.file_data,
          path: action.data?.path
    };
    default:
      return state;
  }
};