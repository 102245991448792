import { combineReducers } from 'redux';
import { modulesReducer } from './modulesReducer';
import { formReducer } from './formReducer';
import { usersReducer } from './usersReducer';
import { modelsReducer } from './modelsReducer';
import { editorReducer } from './editorReducer';

export const Reducers = combineReducers({
    modulesReducer,
    formReducer,
    usersReducer,
    modelsReducer,
    editorReducer,
});