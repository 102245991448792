import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import HanddleActions from '../actions'
import ControlledEditor  from "@monaco-editor/react";
import { Row, Col, Container, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { AiFillDatabase, AiOutlineConsoleSql, AiFillPlaySquare } from 'react-icons/ai'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { t } from '../services'

import * as SRD from "storm-react-diagrams"
require("storm-react-diagrams/dist/style.min.css")

export default function ModuleREST(props) {

    const module    = useSelector(state => state.modulesReducer.module)  
    const connected = useSelector(state => state.modulesReducer.connected)  
    const data      = useSelector(state => state.modulesReducer.connectData)    
    const response  = ""//useSelector(state => state.modelsReducer.statement)

    const [requests, setRequests]           = useState([])
    const [request, setRequest]             = useState({})
    const [filter, setFilter]               = useState('')
    const [isEditorReady, setIsEditorReady] = useState(false)
    
    const editorRef = useRef()

    var action     = {dispatch: useDispatch(), dataValues: {}}
    var params     = {
        create_db : {type: 'REST_request', url: 0, route: '/beedev/MODEL_CREATE_DB', method: 'POST', dataValues: { } },
    }

    useEffect(()=> {

    }, [module, data])

    useEffect(()=> {
        if(props.params.tab == "rest")
            editorMount()

    }, [props.params.tab])

    function editorMount() {
        setIsEditorReady(true)
        handleEditorDidMount(editorRef)
    }

    function handleEditorDidMount(editor) {
        editorRef.current = editor

        setIsEditorReady(true)
    }

    function handleRequestsOpen(key) {
        //let _project = {...project}

        //_project.models[key]._unchecked = !_project.models[key]._unchecked

        //HanddleActions(action, params.statement)

        setRequest({})
    }

    return (
    <Container id="moduleREST" className="bg-primary mt-3 p-3" fluid>
        <Row>
            <Col sm="3">
                <ListGroup className="shadow">
                    <ListGroupItem active tag="button" action>
                        {t('Explorer')}
                        <Input 
                            placeholder={t("Filter")} 
                            onKeyUp={(e)=>setFilter(e.target.value)}
                            disabled="disabled"
                        />
                    </ListGroupItem>
                </ListGroup>
                <p></p>
                <ListGroup className="shadow">
                    <ListGroupItem active tag="button" action>
                        {'History'}
                        <div className="text-center">
                          <small>{t("Not_found")}</small>  
                        </div>
                    </ListGroupItem>
                    {
                    [].map((req, index)=> {
                            if(filter == '' || req.url.indexOf(filter) > -1)
                                return (
                                    <ListGroupItem 
                                        key={`req_${index}`} 
                                        onClick={()=>handleRequestsOpen(req)}
                                        tag="button"
                                        action>
                                            <AiFillDatabase /> {req.url}
                                    </ListGroupItem>
                                )
                        })
                    }
                </ListGroup>
            </Col>
            <Col sm="9">
                <section className="shadow">
                    <Input
                        placeholder={t("URL Dev")} 
                        onKeyUp={(e)=>setFilter(e.target.value)}
                    />

                    <Input
                        className="border-top-0"
                        placeholder={t("URL Production")} 
                        onKeyUp={(e)=>setFilter(e.target.value)}
                    />
                    {
                    isEditorReady &&
                    <ControlledEditor
                        className="shadow"
                        height="90vh" // By default, it fully fits with its parent
                        theme={'vs-dark'}
                        language={'sql'}
                        loading={"Loading..."}
                        value={response || "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"}
                        editorDidMount={handleEditorDidMount}
                    />
                    }
                </section>
            </Col>
        </Row>
    </Container>
    )
}