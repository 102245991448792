const urls = {
    0 : 'http://localhost:1987',
    1 : 'http://api.ahcom.com.br:1987',
}

export default function configs() {
    return {
        urls: urls,
    }
}

export function configAxios(_url = 1, _route, _method, _data) {
    return {
        method: _method,
        url: urls[_url] + _route,
        data: _data,
        headers: { 
                    'Content-Type': 'application/json', 
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('user') || '{}').Token}` 
                 }
    }
}