import React, {useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import HanddleActions from '../actions'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Jumbotron, Row, Col, Button,Container, ListGroup, ListGroupItem, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { FaFolder, FaFolderOpen, FaNode } from 'react-icons/fa'
import { FiDownload, FiSearch, FiTerminal } from 'react-icons/fi'
import { BsToggleOff, BsToggleOn, BsFolderPlus } from 'react-icons/bs'

import BeeIcon from "../beeFront/BeeIcon";
import BeeForm from "../beeFront/BeeForm";
import { t } from "../services/langs";

export default function Module(props) {
    const lang        = props.routeParams.lang
    const parent      = props.routeParams.parent

    const connectPort = useSelector(state => state.modulesReducer.connectPort)
    const connected   = useSelector(state => state.modulesReducer.connected)
    const connectData = useSelector(state => state.modulesReducer.connectData)

    const modules     = useSelector(state => state.modulesReducer.modules)   
    const data        = useSelector(state => state.modulesReducer.data) 
    const form        = useSelector(state => state.formReducer.form)
    const mode        = useSelector(state => state.formReducer.mode)
    
    const dispatch  = useDispatch()
    const history   = useHistory()
    const module    = modules[props.routeParams.module] || modules.projects //TODO fazer modulo dash
    const bee       = {dispatch: dispatch, history: history, module: module, routeParams: props.routeParams}
    const action    = bee //TODO replace and remove

    module.data     = data[props.routeParams.module]
    module.dataBind = Object.assign({}, module.data, data[props.routeParams.parent])

    useEffect(()=> {
        let newModule = modules[props.routeParams.module] || modules.projects //TODO fazer modulo dash

        console.log('# open module: ') || console.log(newModule)

        let params = newModule.actions && props.routeParams.parent
                      ? newModule.actions[newModule.actions.init || 'search'] || {}
                      : {type: 'OPEN_MODULE', module: newModule.name}

        console.log('param init:') || console.log(params)

        dispatch({type: 'HIDDEN_FORM'}) 
        //dispatch({...params, type: 'OPEN_MODULE', module: newModule.name})
        HanddleActions(bee, {...params, dataBind: data})

    }, [props.routeParams.module])

    useEffect(()=>{
        console.log('Receive data: ') || console.log(data)
        console.log(module) //trabalhar aqui...
    }, [data])

    const clickConnect = function() {
        connected
            ? dispatch({type: 'REMOTE_CONNECT', connected: false, isLoading: false})
            : axios.post(`http://localhost:${connectPort}/beedev/getConfig`)
                .then(res => {
                    console.log(res.data)
                    dispatch({type: 'REMOTE_CONNECT_SUCCESS', data: res.data})
                })
    }

    return (
    <Container className="beeview p-2" fluid>
        <Row>
            <Col sm="3">
                {
                module &&
                <Breadcrumb className="ripper">
                    {   <BreadcrumbItem><a href="#" onClick={()=> action.history.push('/') }><FiTerminal />Home</a></BreadcrumbItem>    }
                    {   
                        module && 
                        <BreadcrumbItem><a href="#" onClick={()=> action.history.push(`/${lang}/dash/${module.parent || module.name}`) }>{module.parentTitle || module.title}</a></BreadcrumbItem>
                    }
                    {
                        (module.data || module.parentModule) &&
                        <BreadcrumbItem active>{module.parentModule ? module.parentModule.dataView.title || '####' : module.dataView.title}</BreadcrumbItem>
                    }
                    {
                        parent && 
                        <BreadcrumbItem active>{module.title}</BreadcrumbItem>
                    }
                </Breadcrumb> 
                }
                <ListGroup className="shadow">
                    <ListGroupItem active tag="a" href="#" action>Actions</ListGroupItem>
                    {
                    module.actions.new && 
                    <ListGroupItem className="ripple" tag="a" href="#" onClick={()=> HanddleActions(bee, module.actions.new)} action><BsFolderPlus /> &nbsp; &nbsp; New {module.title}</ListGroupItem>
                    }
                    {
                    module.actions.search &&
                    <ListGroupItem className="ripple" tag="a" href="#" onClick={()=> HanddleActions(bee, module.actions.search)} action><FiSearch /> &nbsp; &nbsp; Search {module.title}</ListGroupItem>
                    }
                    { 
                    module.actions.connectLocalhost && !module.data &&
                        <ListGroupItem className="ripple" tag="a" onClick={()=>clickConnect()} href="#" action>
                            {connected ? <BsToggleOn color="green" /> : <BsToggleOff color="red" />} 
                            &nbsp; &nbsp; {!connected ? 'Connect' : 'Disconnect'} localhost:{connectPort}
                        </ListGroupItem>
                    }
                </ListGroup>
                {
                    connected &&
                    <ListGroup className="shadow mt-2">
                        <ListGroupItem active tag="a" href="#" action>Remote Actions</ListGroupItem>
                        <ListGroupItem className="ripple" tag="a" href="#" action><FaNode /> &nbsp; &nbsp; Node Debug</ListGroupItem>
                    </ListGroup>
                }

                {
                    !connected && (module.name == 'projects') &&
                    <ListGroup className="shadow mt-2">
                        <ListGroupItem active tag="a" href="#" action>Recent</ListGroupItem>
                        <ListGroupItem className="ripple" tag="a" href="#" action><FaFolder /> &nbsp; &nbsp; Project Name</ListGroupItem>
                        <ListGroupItem className="ripple" tag="a" href="#" action><FaFolder /> &nbsp; &nbsp; Morbi leo risus</ListGroupItem>
                        <ListGroupItem className="ripple" tag="a" href="#" action><FaFolder /> &nbsp; &nbsp; Porta ac consectetur ac</ListGroupItem>
                    </ListGroup>
                }
            </Col>
            <Col sm="9">
                {   
                (parent || (module.data && module.childs && module.childs.length > 0)) &&
                (module.parentModule
                    ? [module.parentModule].concat(module.parentModule.childs)
                    : [module].concat(module.childs)
                )
                .map((m, i) => 
                    (!m.title)
                        ? ''
                        : <Button  
                            key={'btn_module_' + m.name}
                            active={false}
                            className="bg-beehive bt-tab mr-1 mb--1 pl-3 pb-2 pr-4"
                            onClick={()=> !m.parent
                                            ? action.history.push(`/${lang}/dash/${m.name}`)
                                            : action.history.push(`/${lang}/dash/${m.parent}/-/${m.name}`)
                            }>
                                <FiDownload className="icon_ext mr-3"/>  
                                {
                                !m.parent 
                                    ? m.dataView.title 
                                    : m.titleS || m.title
                                }
                            </Button>
                )
                }
                { 
                    form
                    ?  <Container className="beesection bg-primary text-light shadow p-4" fluid>
                            <BeeForm
                                bee={bee}
                                module={module} 
                                parent={parent} 
                                form={form} 
                                mode={mode}
                            />
                        </Container>
                    : <section className="row">
                        <Jumbotron className="beeview shadow w-100">
                            {
                            module.parentModule && !module.data && 
                            <p>'{module.parentModule.dataView.title}' no have registers</p> 
                            }
        
                            <h1 className="display-3">
                            { 
                                connected
                                    ? connectData.name
                                    : module.data 
                                        ? module.dataView.title
                                        : 'New ' + module.title
                            }
                            </h1>

                            <p className="lead">
                                { 
                                connected
                                    ? `${t("Version")} ${connectData.version || 0.1}`
                                    : module.data
                                        ? module.dataView.subtitle
                                        : 'Create a new ' + module.title
                                }
                            </p>
                            <hr className="my-2" />
                            <h6>
                                { 
                                connected && module.env 
                                    ? `${t('By')} ${module.env.npm_package_author_name}`
                                    : module.data 
                                        ? module.dataView.description
                                        : ''
                                }
                            </h6>
                            <p> 
                                { 
                                connected && module.env
                                    ? module.env.npm_package_description
                                    : 'This space is reserved for a sumary of this project.'
                                }
                            </p>

                            { 
                            !connected
                            ? <p className="lead mt-5">
                                {   !module.data && module.actions.new &&
                                    <Button color="primary" className="mr-2" onClick={()=> HanddleActions(action, {...module.actions.new })}><BeeIcon icon="new" /> New </Button> 
                                }
                                {   module.data && module.actions.edit &&
                                    <Button color="primary" className="mr-2" onClick={()=> HanddleActions(action, {...module.actions.edit, dataBind: module.dataBind})}><FaFolderOpen /> Edit </Button> 
                                }
                                {   !module.data &&
                                    <Button color="primary" className="mr-2" onClick={()=> HanddleActions(action, {...module.actions.search, data: module.data})}><BeeIcon icon="search" /> Search {module.titleS || module.title}</Button> 
                                }
                                {   module.data &&
                                    <Button color="primary" className="mr-2" onClick={()=> HanddleActions(action, {type: 'CLOSE_DATA'})}><BeeIcon icon="close" /> Close </Button> 
                                }
                                # dev:
                                { 
                                    module.view.layout && 1 == 2 &&
                                    eval(module.view.layout)
                                }
                                </p>
                            : <p>
                                <Button color="primary" className="mr-2"><FiDownload /> Sync To Local</Button>
                                </p>
                            }
                            <p>
                                { 1 == 2 && module.view.model }
                                { 1==2 && module.data && JSON.stringify(module.data) }
                            </p>
                        </Jumbotron>
                    </section>
                }  
            </Col>
        </Row>
    </Container>
    )
}