import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap';
import { IconArrowFolderDown, IconArrowFolderRight, IconJS, IconCSS } from '../icons'
import { t } from '../../services'

export default function TreeFilesPaths({ mapPaths = [], filter, handles }) {

     function Item({_path}) {

          return (
                    <ListGroupItem 
                         key={`path_childs_${_path.path}`}
                         onClick={()=>handles.openPath(_path)}
                         style={{paddingLeft: `${(_path.path.match(/\//g)||[]).length * 20}px`}}
                         tag="button"
                         action>
                              {
                                   _path.ext !== ""
                                   ? <IconJS className="mr-2" />
                                   : _path.open
                                        ? <IconArrowFolderDown />
                                        : <IconArrowFolderRight />
                              }
                              {_path.name}
                    </ListGroupItem>
               )
     }

     return <ListGroup className="shadow overflow-auto" style={{height: "calc(90vh - 110px)"}}>
               {
               !mapPaths.length
               ?    <ListGroupItem active>
                         <div className="text-center">
                              <small>{t("Not_found")}</small>  
                         </div>
                    </ListGroupItem>
               :     mapPaths
                         .filter(_path => _path.parent === "")
                         .sort((a, b) => (a.name.match(/\./g)||[]).length - (b.name.match(/\./g)||[]).length)
                         .map((_path, i)=> {

                              if(i==0)console.log("render list files")

                              if(filter == '' || _path.path.indexOf(filter) > -1)
                                   return <div key={`path_${i}`}>
                                             <Item _path={_path} />
                                             {
                                                  _path.open &&
                                                  mapPaths
                                                  .filter(p => p.parent == _path.path)
                                                  .map((p, i) => <Item key={`item_child_${i}`} _path={p} /> ) //childs
                                             }
                                             </div>
                         })
               }
          </ListGroup>
}