export default {
    Name: "Nome",
    Yes: "Sim",
    No: "Não",
    New: "Novo",
    Cancel: "Cancelar",
    Save: "Salvar",
    Delete: "Deletar",
    Remove: "Remover",
    Create: "Criar",
    User: "Utilizador",
    Password: "Senha",
    Account: "Conta",
    Tools: "Ferramentas",
    Help: "Ajuda",
    Modules: "Módulos",
    Reover: "Recuperar",
    Close: "Fechar",

    Make_your_login: "Faça seu login",  
    Recover_password: "Recuperar senha",
    Confirm_password: "Confirme sua senha",
    Confirm_code: "Confirme o código",
    Create_a_new_account: "Criar uma nova conta",
    Enter_the_code_sent_to_your_email: "Digite o código enviado para o seu e-mail", 
    "Are_you_sure_?": "Tem certeza ?"
}