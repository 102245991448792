import React from "react";
import babel from "@babel/plugin-transform-react-jsx"

const $front   = {}
var $module  = {}

let jsx = '<div>teste deu certo!!!!</div>'

//var jsCode = babel.transform(jsx);

$module.template = `<BeeMenu data={$front.menu} />
                        <BeeTabs data={$front.tabs} />
                        <BeeDiv className='container'> 
                            <BeeDiv className='row fluid'>
                                <BeeDiv className='col md-3'>
                                    <BeeNav />
                                    <BeeWidget type='actions' />
                                </BeeDiv>
                                <BeeDiv className='col md-9'>
                                    <BeeDash module={$module} />
                                </BeeDiv>
                            </BeeDiv>
                    </BeeDiv>`

export default function BeeRender() {


    return <div>




    </div>
}


function teste2(){
    return <div>
                <BeeMenu data={$front.menu} />
                <BeeTabs data={$front.tabs} />
                <BeeDiv className='container'> 
                    <BeeDiv className='row fluid'>
                        <BeeDiv className='col md-3'>
                            <BeeNav />
                            <BeeWidget type='actions' />
                        </BeeDiv>
                        <BeeDiv className='col md-9'>
                            <BeeDash module={$module} />
                        </BeeDiv>
                    </BeeDiv>
                </BeeDiv>
            </div>
}

function BeeMenu(){
    return <></>
}

function BeeTabs(){
    return <></>
}

function BeeDiv(props){
    return <div id="teste">arnaldo liberal dos santos</div>
}

function BeeNav(){
    return <></>
}

function BeeWidget(){
    return <></>
}

function BeeDash() {
    return <></>
}